import React, { useState, useEffect } from "react";
import { Accordeon, Container, Badge } from "../../..";
import css from "./style.module.scss";
import FilterGroup from "./filter-group";
import { ApolloCollors } from "../../../rules/design/apollo-colors";
import { v4 as uuid } from "uuid";
import Sorter from "./sorter";

const buildFilters = (items, label, props) => {
  let carrierKey;

  if (label == "General") carrierKey = "ALL";
  else if (label == "Marketplace") carrierKey = "MARKETPLACE";
  else if (label == "National General") carrierKey = "NATIONAL_GENERAL";
  else if (label == "OneShare") carrierKey = "ONE_SHARE";
  else if (label == "United Healthcare") carrierKey = "UNITED_HEALTHCARE";
  else if (label == "Universal") carrierKey = "UNIVERSAL";


  return (
    <>
      {items.map((item) => {
        if (item.type === "GROUP") {
          return (
            <FilterGroup
              carrierKey={carrierKey}
              onChange={(i, v) => {
                props.onChange && props.onChange(label, i, v);
              }}
              key={label + item.type + item.attribute}
              {...item}
            />
          );
        } else return null;
      })}
    </>
  );
};

const FilterAccordeon = ({ activeTab, facetItem, props }) => {
  const [collapsed, setCollapsed] = useState(
    !(facetItem.label == "General" || facetItem.label == props.activeTab)
  );

  useEffect(() => {
    setCollapsed(
      !(facetItem.label == "General" || facetItem.label == props.activeTab)
    );
  }, [activeTab]);

  if (
    facetItem.label != "National General" &&
    facetItem.label != "Marketplace" &&
    facetItem.label != "Universal" &&
    facetItem.label != "United Healthcare" &&

    facetItem.label != "General"
  ) {
    return null;
  }

  return (
    <Accordeon
      collapsed={collapsed}
      setCollapsed={setCollapsed}
      title={facetItem.label}
    >
      {buildFilters(facetItem.values, facetItem.label, props)}
    </Accordeon>
  );
};

export default (props) => {
  return (
    <Container className={css.searchFiltersContainer}>
      {props.data.map((facetItem) => {
        if (
          facetItem.type === "GROUP" &&
          facetItem.values[0] &&
          facetItem.values[0].values &&
          facetItem.values[0].values.length > 0
        ) {
          return (
            <FilterAccordeon
              props={props}
              key={"group" + facetItem.label}
              activeTab={props.activeTab}
              facetItem={facetItem}
            />
          );
        }
      })}
    </Container>
  );
};
