import React, { useState, useEffect } from "react";
import {
  Container,
  TextInput,
  Icon,
  RadioButton,
  ProductCard,
  OfferCard,
  Select,
  Popup,
  CloseOutlined,
  DatePicker,
} from "../../../";
import axios from "axios";
import css from "./style.module.scss";
import { Button, Collapse, AutoComplete, Input } from "antd";
import agents from "../profile/agents";

import EnrollmentList from "./enrollment-list"
import { useParams } from "@reach/router";

import { v4 as uuidv4 } from "uuid";

import moment from "moment"
import dental from "../offer-card/benefits-list/dental";


const healthSherpaIds = agents.filter(a => a.healthSherpaAgentID).map(a => { return { name: a.firstName + " " + a.lastName, id: a.healthSherpaAgentID } });
const enrollerIds = agents.filter( a => a.natgenEnrollerId).map(a => { return { name: a.firstName + " " + a.lastName, id: a.natgenEnrollerId} });
const aspireKeys = agents.filter(a => a.aspireDentalID).map(a => { return { name: a.firstName + " " + a.lastName, id: a.aspireDentalID } });
const brokerIds = agents.filter(a => a.universalBrokerId).map(a => { return { name: a.firstName + " " + a.lastName, id: a.universalBrokerId } });

const renderTitle = (title) => (
  <Container
    style={{
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    <Container style={{ paddingLeft: "12px" }}>{title}</Container>
    <Container></Container>
  </Container>
);

const renderItem = (value, title, showValue) => ({
  value,
  label: (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {title}
      {/* <span>{showValue ? value : ""}</span> */}
    </div>
  ),
});

const getOptions = (list,title, showValue=true) => [
  {
    label: renderTitle(title),
    options: list.map((e) => renderItem(e.id, e.name, showValue)),
  },
];

const handleInputChange = (event) => {
  const target = event.target;
  const value = target.value;
  return value;
};

const { Panel } = Collapse;

const metalLevelColors = {
  Bronze: "#6A3805",
  Catastrophic: "#575988",
  Gold: "#AF9500",
  Platinum: "#3B3B41",
  Silver: "#B4B4B4",
};

export default (props) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [dateOfBirth, setDateOfBirth] = useState();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  const [spouseFirstName, setSpouseFirstName] = useState();
  const [spouseLastName, setSpouseLastName] = useState();
  const [spouseDateOfBirth, setSpouseDateOfBirth] = useState();
  
  const [uhcKey, setUhcKey] = useState();
  const [dentalKey, setDentalKey] = useState(
    "AFR-20285-170998"
  );
  const [popupTitle, setPopupTitle] = useState(
    "Aspire Dental"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState();
  const spouse = props.params.spouse ? JSON.parse(decodeURIComponent(props.params.spouse)) : null;
  const dependents = props.params.dependents ? JSON.parse(decodeURIComponent(props.params.dependents)) : [];
  dependents.forEach((d,index) => {
    const [dependentFirstName, setDependentFirstName] = useState();
    const [dependentLastName, setDependentLastName] = useState();
    const [dependentDateOfBirth, setDependentDateOfBirth] = useState();

    dependents[index] = {
      id: uuidv4(),
      index,
      ...d,
      firstName: dependentFirstName,
      setFirstName: setDependentFirstName,
      lastName: dependentLastName,
      setLastName: setDependentLastName,
      dateOfBirth: dependentDateOfBirth,
      setDateOfBirth: setDependentDateOfBirth
    }
  })

  let agent:any = props.selectedAgent
    ? agents.filter(
        (a) => props.selectedAgent === a.firstName + " " + a.lastName
      )[0]
    : {};

  const [agentFirstName, setAgentFirstName] = useState(
    props.selectedAgent ? agent.firstName : ""
  );
  const [agentLastName, setAgentLastName] = useState(
    props.selectedAgent ? agent.lastName : ""
  );
  const [agentEmail, setAgentEmail] = useState(
    props.selectedAgent ? agent.email : ""
  );

  const [enrollmentEnabled, setEnrollment] = useState(true);
  const {
    results,
    selectedItems,
    sendQuote,
    finishEnrollment,
    universalEnrollment,
  } = props;

  const selectedPlans = results.plans["ALL"].filter((plan) =>
    props.selectedItems.has(plan.id)
  );
  const nationalGeneralPlans = results.plans[
    "NATIONAL_GENERAL"
  ].filter((plan) => props.selectedItems.has(plan.id));
  const marketPlacePlans = results.plans["MARKETPLACE"].filter((plan) =>
    props.selectedItems.has(plan.id)
  );
  const aspireDentalPlans = results.plans["ASPIRE_DENTAL"].filter((plan) =>
    props.selectedItems.has(plan.id)
  );
  const universalPlans = results.plans["UNIVERSAL"].filter((plan) =>
    props.selectedItems.has(plan.id)
  );
  const unitedHealthcarePlans = results.plans["UNITED_HEALTHCARE"].filter((plan) =>
    props.selectedItems.has(plan.id)
  );

  useEffect(() => {
    agent = props.selectedAgent
      ? agents.filter(
          (a) => props.selectedAgent === a.firstName + " " + a.lastName
        )[0]
      : {};
    setAgentFirstName(props.selectedAgent ? agent.firstName : "");
    setAgentLastName(props.selectedAgent ? agent.lastName : "");
    setAgentEmail(props.selectedAgent ? agent.email : "");
  }, [props.selectedAgent]);

  const hasInvalidContactInfo = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
      return true;
    }

    const re2 = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/

    if (!re2.test(String(phone).toLowerCase())) {
      return true
    }

    return !firstName || !lastName || !email || !phone;
  };

  const hasInvalidAgentInfo = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(agentEmail).toLowerCase())) {
      return true;
    }

    return !agentFirstName || !agentLastName || !agentEmail;
  };

  const hasIncompleteUHCData = () => {

    let spouseInvalid = false;
    let dependentsInvalid = false;

    if(spouse) {
      spouseInvalid = !spouseFirstName || !spouseLastName || !spouseDateOfBirth
    }

    if (dependents && dependents.length) {
      dependentsInvalid = !dependents.some( d => !d.firstName || !d.lastName || !d.effectiveDate);
    }

    return spouseInvalid || dependentsInvalid || !effectiveDate
  }


  return (
    <>
      <Container className={css.thirdStep}>
        <Container className={css.finalForm}>
          <Container className={css.cardHeader}>Checkout <Container onPress={ () => { props.goBack && props.goBack()}} className={ css.closeButton}> <CloseOutlined/></Container></Container>
          <Container className={css.content}>
            <Container className={css.leftPart}>
              <Container className={css.cartProductsContainer}>
                <Container className={css.cartProductsTitle}>
                  Selected Products
                </Container>
                <Collapse ghost className={css.cartCollapse}>
                  {selectedPlans.map((p) => {
                    return (
                      <Panel
                        showArrow={false}
                        key={p.id + "asd"}
                        className={css.cartCollapseItem}
                        header={<ProductCard plan={p} />}
                      >
                        <Container
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <OfferCard hideHeader {...p} />
                        </Container>
                      </Panel>
                    );
                  })}
                </Collapse>

                <Container
                  style={{
                    bottom: "0px",
                    width: "100%",

                  }}
                  className={css.productsTotalPremiumContainer}
                >
                  <Container className={css.totalPremium}>Total premium</Container>
                  <Container className={`${css.totalPremiumValue}`}>
                    ${" "}
                    {selectedPlans
                      .reduce((a, b) => {
                        return a + b.premium.value;
                      }, 0)
                      .toFixed(2)}
                  </Container>
                </Container>
              </Container>
            </Container>
            <Container className={css.finalFormInputs}>
              {(universalPlans.length > 0 ||
                nationalGeneralPlans.length > 0 || unitedHealthcarePlans.length > 0) && (
                <Container>
                  <Container className={css.whatToDoNext}>
                    Main Applicant
                  </Container>

                  <Container className={css.finalFormInput}>
                    <TextInput
                      name="test-enrollment-contact-input-first-name"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(handleInputChange(e));
                      }}
                      containerclassname={css.finalFormInputText}
                      focusedlabel="First Name"
                      placeholder="First Name"
                    />
                  </Container>
                  <Container className={css.finalFormInput}>
                    <TextInput
                      name="test-enrollment-contact-input-last-name"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(handleInputChange(e));
                      }}
                      containerclassname={css.finalFormInputText}
                      focusedlabel="Last Name"
                      placeholder="Last Name"
                    />
                  </Container>
                  <Container className={css.finalFormInput}>
                    <TextInput
                      name="test-enrollment-contact-input-email"
                      value={email}
                      onChange={(e) => {
                        setEmail(handleInputChange(e));
                      }}
                      containerclassname={css.finalFormInputText}
                      mask={
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      }
                      focusedlabel="Email"
                      placeholder="Email"
                    />
                  </Container>
                  <Container className={css.finalFormInput}>
                    <TextInput
                      name="test-enrollment-contact-input-phone"
                      value={phone}
                      onChange={(e) => {
                        const x = e.target.value
                          .replace(/\D/g, "")
                          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                        e.target.value = !x[2]
                          ? x[1]
                          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");

                        setPhone(handleInputChange(e));
                      }}
                      containerclassname={css.finalFormInputText}
                      mask={/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/}
                      focusedlabel="Phone"
                      placeholder="Phone"
                    />
                  </Container>
                </Container>
              )}
              {universalPlans.length > 0 && (
                <Container>
                  <Container
                    style={{ marginTop: "12px" }}
                    className={css.whatToDoNext}
                  >
                    Agent
                  </Container>
                  <Container className={css.finalFormInput}>
                    <TextInput
                      name="test-enrollment-contact-input-first-name"
                      value={agentFirstName}
                      onChange={(e) => {
                        setAgentFirstName(handleInputChange(e));
                      }}
                      containerclassname={css.finalFormInputText}
                      placeholder="First Name"
                      focusedlabel="First Name"
                    />
                  </Container>
                  <Container className={css.finalFormInput}>
                    <TextInput
                      name="test-enrollment-contact-input-first-name"
                      value={agentLastName}
                      onChange={(e) => {
                        setAgentLastName(handleInputChange(e));
                      }}
                      containerclassname={css.finalFormInputText}
                      placeholder="Last Name"
                      focusedlabel="Last Name"
                    />
                  </Container>
                  <Container className={css.finalFormInput}>
                    <TextInput
                      name="test-enrollment-contact-input-email"
                      value={agentEmail}
                      onChange={(e) => {
                        setAgentEmail(handleInputChange(e));
                      }}
                      containerclassname={css.finalFormInputText}
                      mask={
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      }
                      placeholder="Email"
                      focusedlabel="Email"
                    />
                  </Container>
                </Container>
              )}
              {unitedHealthcarePlans.length > 0 && (
                <>
                <Container className={css.finalFormInput}>
                  <DatePicker
                      disabledDate={(m) => {
                        const lowerLimit = moment().add(- (Number(props.params.age) + 1), "years");
                        const upperLimit = moment().add(- Number(props.params.age), "years");
                        if (m < lowerLimit || m > upperLimit) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      defaultPickerValue={moment().add(
                        -(Number(props.params.age) * 366),
                        "days"
                      )}
                  className={ css.datePicker}
                    value={dateOfBirth}
                    onChange={(e) => {
                      setDateOfBirth(e);
                    }}
                    before={"Date of Birth"}
                    focusedLabel="Date of Birth"
                  />
                </Container>
                <Container className={css.finalFormInput}>
                  <DatePicker className={ css.datePicker}
                      defaultPickerValue={moment().add(30,
                        "days"
                      )}
                      disabledDate={(m) => {
                        const now = moment(new Date());
                        if (now.add(30,"day") >= m || now.add(90, "day") <= m) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    value={effectiveDate}
                    onChange={(e) => {
                      setEffectiveDate(e);
                    }}
                    before={"Effective Date"}
                    focusedLabel="Effective Date"
                  />
                </Container>
                </>
              )
              }
              {unitedHealthcarePlans.length > 0 && spouse && 
                  <>
                    <Container className={css.whatToDoNext}>
                      Spouse
                    </Container>
                    <Container className={css.finalFormInput}>
                    <TextInput
                      value={spouseFirstName}
                      onChange={(e) => {
                        setSpouseFirstName(handleInputChange(e));
                      }}
                      containerclassname={css.finalFormInputText}
                      placeholder="First Name"
                      focusedlabel="First Name"
                    />
                    </Container>
                    <Container className={css.finalFormInput}>
                    <TextInput
                      value={spouseLastName}
                      onChange={(e) => {
                        setSpouseLastName(handleInputChange(e));
                      }}
                      containerclassname={css.finalFormInputText}
                      placeholder="Last Name"
                      focusedlabel="Last Name"
                    />
                    </Container>
                    <Container className={css.finalFormInput}>
                  <DatePicker disabledDate={(m) => {
                    const lowerLimit = moment().add(- (Number(spouse.age) + 1), "years");
                    const upperLimit = moment().add(- Number(spouse.age), "years");
                    if (m < lowerLimit || m > upperLimit) {
                      return true;
                    } else {
                      return false;
                    }
                  }}
                    defaultPickerValue={moment().add(
                      -(Number(spouse.age) * 366),
                      "days"
                    )} className={ css.datePicker}
                    value={spouseDateOfBirth}
                    onChange={(e) => {
                      setSpouseDateOfBirth(e);
                    }}
                    before={"Date of Birth"}
                    focusedLabel="Date of Birth"
                  />
                  </Container>
                  </>  
              }
              {unitedHealthcarePlans.length > 0 && dependents.length > 0 && 
              dependents.map( d =>
                <>
                  <Container className={css.whatToDoNext}>
                    Dependent {d.index + 1}  <span className={css.dependentDetails}>({d.age} year(s) old, {d.gender.toLowerCase()}) </span>
                  </Container>
                  <Container className={css.finalFormInput}>
                    <TextInput
                      value={d.firstName}
                      onChange={(e) => {
                        console.log("d",d)
                        d.setFirstName(handleInputChange(e));
                      }}
                      containerclassname={css.finalFormInputText}
                      placeholder="First Name"
                      focusedlabel="First Name"
                    />
                  </Container>
                  <Container className={css.finalFormInput}>
                    <TextInput
                      value={d.lastName}
                      onChange={(e) => {
                        d.setLastName(handleInputChange(e));
                      }}
                      containerclassname={css.finalFormInputText}
                      placeholder="Last Name"
                      focusedlabel="Last Name"
                    />
                  </Container>
                  <Container className={css.finalFormInput}>
                    <DatePicker 
                      disabledDate={(m) => {
                        const lowerLimit = moment().add(- (Number(d.age) + 1), "years");
                        const upperLimit = moment().add(- Number(d.age), "years");
                        if (m < lowerLimit || m > upperLimit) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      defaultPickerValue={moment().add(
                        -(Number(d.age) * 366),
                        "days"
                      )}
                    className={ css.datePicker}
                      value={d.dateOfBirth}
                      onChange={(e) => {
                        d.setDateOfBirth(e);
                      }}
                      before={"Date of Birth"}
                      focusedLabel="Date of Birth"
                    />
                  </Container>
                </>
              )
              }

              {marketPlacePlans.length > 0 && <EnrollmentList requireId defaultKey={marketPlacePlans[0].health_sherpa_agent_key.value} keys={[]} selectedAgent={props.selectedAgent}  title="Marketplace" onEnrollmentButtonClick={(k) => {

                marketPlacePlans.forEach( p => 
                  props.redirectToHealthSherpa &&
                  props.redirectToHealthSherpa(p,k)
                  )
              }} disabled={false} enrollmentKeyLabel="Agent ID (Optional)" options={getOptions(healthSherpaIds,"Agent")} plans={marketPlacePlans} /> }

              {nationalGeneralPlans.length > 0 && <EnrollmentList keys={enrollerIds} selectedAgent={props.selectedAgent}  requireId disabled={
                hasInvalidContactInfo()
              } title="National General" onEnrollmentButtonClick={(key) => { 
                finishEnrollment &&
                  finishEnrollment(
                    firstName,
                    lastName,
                    email,
                    phone,
                    key
                  );

              }} enrollmentKeyLabel="Enroller ID"  options={getOptions(enrollerIds,"Agent")}
                plans={nationalGeneralPlans.map(plan => ({
                  ...plan,
                  premium: {
                    value: props.specialRates
                      ? props.specialRates.priorCoverage
                        ? props.specialRates.prefferedRate
                          ? plan.premium_options.options[2]
                            .premium
                          : plan.premium_options.options[1]
                            .premium
                        : plan.premium_options.options[0]
                          .premium
                      : plan.premium_options.options[0]
                        .premium,
                  },
                }))}/> }

              {aspireDentalPlans.length > 0 && <EnrollmentList keys={aspireKeys} selectedAgent={props.selectedAgent}  disabled={false} requireId title={ "Aspire Dental"} onEnrollmentButtonClick={(key) => {
                
                const keyparts = key.split("-");
                const afrepId = keyparts[0] + "-" + keyparts[1];
                const affiliateId = keyparts[2]
                setDentalKey(`https:///aspire.brings.healthcare/member-direct/aspire-dental?afrepid=${afrepId}&affiliateId=${affiliateId}`)
                setTimeout(() => setPopupVisible(true),200);
              }} enrollmentKeyLabel="Aspire Key" options={getOptions(aspireKeys,"Agent")} plans={aspireDentalPlans} />}

              {universalPlans.length > 0 && <EnrollmentList requireId keys={brokerIds} selectedAgent={props.selectedAgent} title="Universal" onEnrollmentButtonClick={(key) => {
                universalEnrollment &&
                  universalEnrollment({
                    firstName,
                    lastName,
                    email,
                    phone,
                    agentFirstName,
                    agentLastName,
                    agentEmail,
                    brokerId: key
                  });
              }} disabled={hasInvalidAgentInfo() || hasInvalidContactInfo()} enrollmentKeyLabel="Broker NPN" options={getOptions(brokerIds, "Agent")} plans={universalPlans} />}
              
              {unitedHealthcarePlans.length > 0 && <EnrollmentList keys={[]} selectedAgent={props.selectedAgent} disabled={ hasInvalidContactInfo() || hasIncompleteUHCData() } requireId title={ "United Healthcare"} isLoading={isLoading} onEnrollmentButtonClick={ async (key) => {
                setIsLoading(true);

                try {
                const response = await axios({
                  method: "post",
                  url: `${props.baseUrl}/v1/enrollment/uhc`,
                  data: {
                    location: {
                      "zip_code": props.params.zip_code,
                      "state": props.params.state
                    },
                    "primary_applicant": {
                      "first_name": firstName,
                      "last_name": lastName,
                      "date_of_birth": dateOfBirth.toISOString().split("T")[0],
                      "gender": props.params.gender,
                      "uses_tobacco": props.params.uses_tobacco,
                      "email": email,
                      "phone": phone
                  },
                  "spouse": spouse ? {
                    "first_name": spouseFirstName,
                    "last_name": spouseLastName,
                    "date_of_birth": spouseDateOfBirth.toISOString().split("T")[0],
                    "gender": spouse.gender,
                    "uses_tobacco": spouse.uses_tobacco
                  } : null,
                    dependents: dependents.map(d => ({
                      "first_name": d.firstName,
                      "last_name": d.lastName,
                      "date_of_birth": d.dateOfBirth.toISOString().split("T")[0],
                      "gender": d.gender,
                      "uses_tobacco": d.uses_tobacco
                  })),
                  "effective_date": effectiveDate.toISOString().split("T")[0],
                  "broker_id": 44404,
                  "selected_plan": {
                    "name": unitedHealthcarePlans[0].plan_name.value,
                    "network": unitedHealthcarePlans[0].insurance_network.value,
                    "coinsurance": unitedHealthcarePlans[0].benefits[0].details,
                    "deductible": unitedHealthcarePlans[0].deductible.value,
                    "rate_amount": unitedHealthcarePlans[0].premium.value, 
                    "type": unitedHealthcarePlans[0].info.value
                  }
                  },
                  headers: {
                    "X-Apollo-Quotes-Source": "",
                  },
                });
                
                setIsLoading(false);
                setUhcKey(response.data.BrokerSingleSignOnURL);
              } catch (ex) {
                alert("ops there was an error")
                console.log("ERROR >>>>>>>", ex);
                setIsLoading(false);
              }
              }} enrollmentKeyLabel="Broker NPN" options={[]} plans={unitedHealthcarePlans} />
              }
            {uhcKey && <Container className={css.sendButton} onPress={() => {window.open(uhcKey, "_blank");}}><Button name="uhc-enrollment-btn" type="primary">Continue to UHC</Button></Container>}
            </Container>
          </Container>
        </Container>
      </Container>
      <Popup
        title={ popupTitle }
        className={css.popupContainer}
        visible={popupVisible}
        onClose={() => {
          setPopupVisible(false);
        }}
        openOutside={dentalKey}
      >
        <iframe style={{ width: "100%" }} src={dentalKey} />
      </Popup>
    </>
  );
};
